<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Informasi Akun
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Atur informasi akun anda</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          style="background-color: #fec5bb;"
          class="btn mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          Simpan
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          Batal
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <!-- <h5 class="font-weight-bold mb-6">Customer Info</h5> -->
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Foto Profil</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="image-input image-input-outline" id="kt_profile_avatar">
              <div
                class="image-input-wrapper"
                :style="{ backgroundImage: `url(${current_photo})` }"
              ></div>
              <label
                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="change"
                data-toggle="tooltip"
                title=""
                data-original-title="Change avatar"
              >
                <i class="fa fa-pen icon-sm text-muted"></i>
                <input
                  type="file"
                  name="profile_avatar"
                  id="profile_photo"
                  accept=".png, .jpg, .jpeg"
                  @change="onFileChange($event)"
                />
                <input type="hidden" name="profile_avatar_remove" />
              </label>
              <span
                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="cancel"
                data-toggle="tooltip"
                title="Cancel avatar"
              >
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
              <span
                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="remove"
                data-toggle="tooltip"
                title="Remove avatar"
                @click="removeImage"
              >
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
            </div>
            <span class="form-text text-muted"
              >Allowed file types: png, jpg, jpeg.</span
            >
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Nama Pengguna</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              ref="name"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-bind:value="userObj.name"
              rounded
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Email</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-at"></i>
                </span>
              </div>
              <input
                ref="email"
                type="text"
                class="form-control form-control-lg form-control-solid"
                placeholder="Email"
                v-bind:value="userObj.email"
              />
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Jabatan</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              ref="jabatan"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-bind:value="userObj.jabatan"
              disabled
            />
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Services from "@/core/services/notasis-api/Services";
import storagelocal from "@/core/services/storagelocal.service";
import Swal from "sweetalert2";

export default {
  name: "PersonalInformation",
  data() {
    return {
      default_photo: "media/users/blank.png",
      current_photo: null,
      users: [],
     
      profile_photo: null,
      baseUrlUpload: ApiService.getBaseUrlUpload(),

      userObj : {
        name: '',
        email: '',
        jabatan: '',
      },
      isRemoveImg: 0
    };
  },
  mounted() {
    this.loadPage()
  },
  methods: {
    removeImage: function (e) {
      e
      this.current_photo = this.default_photo;
      this.profile_photo = '';
      this.isRemoveImg = 1
    },

    cancel() {
      this.$refs.name.value = this.users[0].name;
      this.$refs.email.value = this.users[0].email;
      this.$refs.jabatan.value = this.users[0].jabatan;
      this.current_photo = this.current_photo;
    },

    saveAll(saveObj){
      return new Promise(resolve => {
            Services.PostData(ApiService, "users", saveObj, response=>{
              resolve(response.data);
              if(response.status == 1000){
                var vm = this
                storagelocal.setLocalStorage('photo_conv', vm.current_photo)
                Swal.fire({
                    title: "",
                    text: "Sukses Merubah Data",
                    icon: "success",        
                    heightAuto: false,
                    timer: 1500
                });
              }else{
                Swal.fire({
                    title: "",
                    text: "Gagal Menambah Data",
                    icon: "info",
                    heightAuto: false,
                    timer: 1500
                });
              }
            }, err =>{
              err
            })
        });
    },

    async save (){
      var saveObj = {}
      var vm = this
        saveObj = Object.assign({}, vm.userObj)

        saveObj.source_upload = vm.profile_photo
        saveObj.name = this.$refs.name.value
        saveObj.email = this.$refs.email.value
        // saveObj.isRemoveImg = this.isRemoveImg
        // saveObj.MenuUrl = "/hakakses/roleuser"
        if(this.isRemoveImg)
          saveObj.path_file = ''

        saveObj.UID = storagelocal.getLocalStorage('UID'),
        saveObj.Token = storagelocal.getLocalStorage('token'),
        saveObj.Trigger = 'U'
        // saveObj.Route = 'update_general'
        
        Promise.all([await this.saveAll(saveObj)])
          .then((results8)=>{
          storagelocal.setLocalStorage('photo_conv', vm.current_photo)
          window.location.reload();
          results8
          // this.loadPage()
        })
      // this.close()
    },

    onFileChange(e) {
        var files = ''

        if(!e)
        return

        files = e
        this.isRemoveImg = 1
        this.createImage(files);
    },

    createImage(files) {
      var image = new Image();
      var reader = new FileReader();
      var vm = this;
      image
      reader.onload = (e) => {
        vm.current_photo = e.target.result;
      };
      if (files) {
          reader.readAsDataURL(files.target.files[0]);
      }
      this.profile_photo = files.target.files[0]
    },

    getDataUser(){
      return new Promise(resolve => {
      var mydata = {
          UID 		: storagelocal.getLocalStorage('UID'),
          Token		: storagelocal.getLocalStorage('token'),
          Trigger	: 'R',
          // Route   : 'login',
          id      : storagelocal.getLocalStorage('UID'),
        }

        Services.PostData(ApiService, "users", mydata, async response=>{
          var responseCace = response.data
          var vm = this
          vm.users = responseCace
    
          vm.userObj = Object.assign({}, vm.users[0])
        
          vm.current_photo = vm.default_photo

          // console.log(vm.isRemoveImg)
          if(responseCace[0].path_file)
            vm.current_photo = await vm.getPhotoProfile(responseCace[0].path_file, vm.baseUrlUpload)
            // storagelocal.setLocalStorage('photo_conv', vm.current_photo)
          resolve(this.users);
          }, err =>{
          err
        })
      });        
    },

    getBlobFromUrl(myImageUrl) {
        return new Promise((resolve, reject) => {
            let request = new XMLHttpRequest();
            request.open('GET', myImageUrl, true);
            request.responseType = 'blob';
            request.onload = () => {
                resolve(request.response);
            };
            request.onerror = reject;
            request.send();
        })
    },

    async getPhotoProfile(item, baseUrlUpload){
        let myBlob = ''
        if(item != null) {
          myBlob = await this.getBlobFromUrl(baseUrlUpload + '/' + item);
        }
        return this.createImageProfile(myBlob)
    },

    createImageProfile(files) {
      if (files) {
        var reader = new FileReader();
        reader.readAsDataURL(files);
        // this.profile_photo = convphoto
        return new Promise(resolve => {
          reader.onload = (e) => {
              resolve( e.target.result)
          };
        })
      }
    },

    async loadPage(){
        Promise.all([ await this.getDataUser(),
                    ])
        .then((results)=> {
          results
        })
    },
  },
  computed: {
  }
};
</script>