<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">Ubah Password</h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Ubah password akun anda</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="submit"
          class="btn btn-primary mr-2"
          style="background-color: #003F88;"
          @click="save()"
          ref="kt_save_changes"
        >
          Simpan
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          Batal
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form" id="kt_password_change_form">
      <div class="card-body">
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert"
            >Password Saat Ini</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              type="password"
              class="form-control form-control-lg form-control-solid mb-2"
              v-bind:class="fieldvalidation"
              value=""
              placeholder="Password Saat Ini"
              name="current_password"
              ref="current_password"
            />
            <!-- <a href="#" class="text-sm font-weight-bold">Forgot password ?</a> -->
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert"
            >Password Baru</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              type="password"
              class="form-control form-control-lg form-control-solid"
              value=""
              placeholder="Password Baru"
              name="new_password"
              ref="new_password"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert"
            >Ulangi Password</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              type="password"
              class="form-control form-control-lg form-control-solid"
              value=""
              placeholder="Ulangi Password"
              name="verify_password"
              ref="verify_password"
            />
          </div>
        </div>
      </div>
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
// import { mapGetters } from "vuex";
// import { UPDATE_PASSWORD } from "@/core/services/store/auth.module";
import KTUtil from "@/assets/js/components/util";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import ApiService from "@/core/services/api.service";
import Services from "@/core/services/notasis-api/Services";
import storagelocal from "@/core/services/storagelocal.service";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import Swal from "sweetalert2";
var CryptoJS = require("crypto-js");

export default {
  name: "ChangePassword",
  data() {
    return {
      password: "",
      fieldvalidation: "",
      valid: true
    };
  },
  mounted() {
    const password_change_form = KTUtil.getById("kt_password_change_form");
    // var curr_password = this.currentUser.password;

    this.fv = formValidation(password_change_form, {
      fields: {
        current_password: {
          validators: {
            notEmpty: {
              message: "Current password is required"
            },
            // identical: {
            //   compare: function() {
            //     if(this.status != 1000)
            //     return false;
            //   },
            //   message: "Wrong password"
            // }
          }
        },
        new_password: {
          validators: {
            notEmpty: {
              message: "New password is required"
            }
          }
        },
        verify_password: {
          validators: {
            notEmpty: {
              message: "Confirm password is required"
            },
            identical: {
              compare: function() {
                return password_change_form.querySelector(
                  '[name="new_password"]'
                ).value;
              },
              message: "The password and its confirm are not the same"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap(),
        submitButton: new SubmitButton()
      }
    });
  },
  methods: {
    saveAll(saveObj){
      return new Promise(resolve => {
            Services.PostData(ApiService, "users", saveObj, response=>{
              resolve(response.data);
              this.status = response.status
              if(response.status == 1000){
                this.fieldvalidation = ""
                Swal.fire({
                    title: "",
                    text: "Sukses Merubah Data",
                    icon: "success",
                    heightAuto: false,
                    timer: 
                    1500
                });
                setTimeout(() => {
                  this.$router.push({ name: "dashboard" })
                }, 2000);
              }else{
                this.fieldvalidation = "is-invalid"
                Swal.fire({
                    title: "",
                    text: "Password Lama Tidak Sesuai",
                    icon: "info",  
                    heightAuto: false,
                    timer: 1500
                });
              }
            }, err =>{
              err
            })
        });
    },

    async save (){
      var saveObj = {}
        saveObj.old_password =  CryptoJS.SHA256(this.$refs.current_password.value).toString()
        // saveObj.password = CryptoJS.SHA256(this.$refs.new_password.value).toString()
        saveObj.password = CryptoJS.SHA256(this.$refs.verify_password.value).toString()
        saveObj.id = storagelocal.getLocalStorage('UID'),
        saveObj.MenuUrl = "/hakakses/roleuser"
        saveObj.UID = storagelocal.getLocalStorage('UID'),
        saveObj.Token = storagelocal.getLocalStorage('token'),
        saveObj.Trigger = 'U'
        saveObj.Route = 'password'
        
        Promise.all([await this.saveAll(saveObj)])
          .then((results8)=>{
          results8
          // this.loadPage()
        })
      // this.close()
    },

    cancel() {
      this.fv.resetForm();
      this.$refs.current_password.value = "";
      this.$refs.new_password.value = "";
      this.$refs.verify_password.value = "";
    }
  },
  computed: {
    // ...mapGetters(["currentUser"])
  }
};
</script>
